import { useEffect } from "react";
import { navigate } from "@reach/router";

import useSitePages from "app/hooks/useSitePages";

const Redirect = () => {
  const { homePath } = useSitePages();

  useEffect(() => {
    navigate(homePath);
  }, [homePath]);

  return null;
};

export default Redirect;
